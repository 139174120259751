<template>
  <base-material-card
    :icon="userOrCompany.icon"
    color="secondary"
    :title="userOrCompany.title"
  >
    <v-form
      ref="generalForm"
      lazy-validation
      @submit.prevent="updateGeneral"
    >
      <v-row v-if="generalInfo.user">
        <v-col
          cols="12"
          md="4"
          class="py-0"
        >
          <v-text-field
            v-model="generalInfo.first_name"
            label="First Name"
            :rules="[rules.required]"
          />
        </v-col>
        <v-col
          cols="12"
          md="4"
          class="py-0"
        >
          <v-text-field
            v-model="generalInfo.last_name"
            label="Last Name"
            :rules="[rules.required]"
          />
        </v-col>
        <v-col
          cols="12"
          md="4"
          class="py-0"
        >
          <v-text-field
            v-model="generalInfo.ssn"
            label="SSN"
          />
        </v-col>
        <v-col
          cols="12"
          md="4"
          class="py-0"
        >
          <v-text-field
            v-model="generalInfo.email"
            label="Email"
          />
        </v-col>
        <v-col
          cols="12"
          md="4"
          class="py-0"
        >
          <v-autocomplete
            v-model="generalInfo.user_class_id"
            label="Class *"
            item-text="name"
            item-value="id"
            :items="mixinItems.userClasses.filter(userClass => userClass.is_customer)"
            :loading="loadingMixins.userClasses"
            :rules="[rules.required]"
            @change="fetchCompanies"
          />
        </v-col>
        <v-col
          cols="12"
          md="4"
          class="py-0"
        >
          <v-autocomplete
            v-model="generalInfo.company_id"
            label="Company"
            item-text="name"
            item-value="id"
            :items="companies"
            :loading="loadingCompanies"
          />
        </v-col>
      </v-row>

      <v-row v-else>
        <v-col
          cols="12"
          md="4"
          class="py-0"
        >
          <v-text-field
            v-model="generalInfo.name"
            label="Name *"
            :rules="[rules.required]"
          />
        </v-col>
        <v-col
          cols="12"
          md="4"
          class="py-0"
        >
          <v-text-field
            v-model="generalInfo.tin"
            label="Federal Employer Identification Number (FEIN)"
          />
        </v-col>
        <v-col
          cols="12"
          md="4"
          class="py-0"
        >
          <v-text-field
            v-model="generalInfo.dba"
            label="Doing Business As Name"
          />
        </v-col>
        <v-col
          cols="12"
          md="4"
          class="py-0"
        >
          <v-autocomplete
            v-model="generalInfo.company_class_id"
            label="Class *"
            item-text="name"
            item-value="id"
            :items="mixinItems.companyClasses.filter(companyClass => companyClass.is_customer)"
            :loading="loadingMixins.companyClasses"
            :rules="[rules.required]"
          />
        </v-col>
        <v-col
          cols="12"
          md="4"
          class="py-0"
        >
          <v-autocomplete
            v-model="generalInfo.company_type_id"
            label="Type"
            item-text="type"
            item-value="id"
            :items="mixinItems.companyTypes"
            :loading="loadingMixins.companyTypes"
          />
        </v-col>
      </v-row>

      <v-btn
        fab
        absolute
        bottom
        right
        small
        type="submit"
        :loading="updatingGeneral"
      >
        <v-icon>mdi-content-save</v-icon>
      </v-btn>
    </v-form>
  </base-material-card>
</template>

<script>
  import axios from 'axios'
  import { mapActions } from 'vuex'
  import { Api } from '@/apis'
  import { fetchInitials } from '@/mixins/fetchInitials'
  import { MIXINS } from '@/constants'

  export default {
    mixins: [
      fetchInitials([
        MIXINS.companyClasses,
        MIXINS.companyTypes,
        MIXINS.userClasses,
      ]),
    ],

    props: {
      customer: {
        type: Object,
        default: () => ({}),
      },
    },

    data: () => ({
      rules: {
        required: value => !!value || 'This field is required.',
      },
      updatingGeneral: false,
      generalInfo: {},
      companies: [],
      loadingCompanies: false,
    }),

    computed: {
      userOrCompany () {
        const user = { icon: 'mdi-account', title: 'User General Information', url: 'individual/individual/', request: 'userRequest' }
        const company = { icon: 'mdi-domain', title: 'Company General Information', url: 'company/company/', request: 'companyRequest' }
        return this.generalInfo.user ? user : company
      },
    },

    mounted () {
      this.generalInfo = Object.assign({}, this.customer)
      if (this.generalInfo.user_class_id) {
        this.fetchCompanies(this.generalInfo.user_class_id)
      }
    },

    methods: {
      ...mapActions({
        showSnackBar: 'showSnackBar',
      }),

      async updateGeneral () {
        if (this.$refs.generalForm.validate()) {
          this.updatingGeneral = true
          const request = {
            companyRequest: {
              name: this.generalInfo.name,
              dba: this.generalInfo.dba,
              tin: this.generalInfo.tin,
              company_type_id: this.generalInfo.company_type_id,
              company_class_id: this.generalInfo.company_class_id,
            },
            userRequest: {
              first_name: this.generalInfo.first_name,
              last_name: this.generalInfo.last_name,
              ssn: this.generalInfo.ssn,
              email: this.generalInfo.email,
              user_class_id: this.generalInfo.user_class_id,
              company_id: this.generalInfo.company_id,
            },
          }

          await new Api().put({
            url: this.userOrCompany.url + this.generalInfo.id,
            request: request[this.userOrCompany.request],
          })
          this.updatingGeneral = false
        }
      },

      async fetchCompanies (userClass) {
        this.loadingCompanies = true
        try {
          const response = await axios.get('customer/companies/' + userClass)
          this.companies = response.data
        } catch (error) {
          this.showSnackBar({ text: error, color: 'error' })
        }
        this.loadingCompanies = false
      },
    },
  }
</script>
